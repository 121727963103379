
import {Vue, Component, Prop, Emit} from 'vue-property-decorator'

type TButtonSize = 'xs' | 's' | 'm' | 'l' | 'xl'
type TButtonColor = 'accent' | 'info' | 'neutral' | 'transparent'
type TButtonTag = 'button' | 'router-link' | 'a'

@Component
export default class Button extends Vue {
  @Prop({type: String, default: 'button'})
  readonly tag?: TButtonTag

  @Prop({type: String, default: 'm'})
  readonly size?: TButtonSize

  @Prop({type: String, default: 'accent'})
  readonly color?: TButtonColor

  @Prop(Boolean)
  readonly disabled?: boolean

  @Prop(Boolean)
  readonly icon?: boolean

  @Prop(Boolean)
  readonly outline?: boolean

  @Prop(Boolean)
  readonly tone?: boolean

  @Prop(Boolean)
  readonly volumetric?: boolean

  get classes () {
    return [
      `c-button_size-${this.size}`,
      `c-button_color-${this.color}`,
      {
        'c-button_outline': this.outline,
        'c-button_icon': this.icon,
        'c-button_tone': this.tone,
        'c-button_volumetric': this.volumetric
      }
    ]
  }

  @Emit('click')
  onClick() {}
}
