
import {Vue, Component, Prop, VModel, Emit, Inject, Watch} from 'vue-property-decorator'
import {getNoun} from '@/utils/plural.util'
import Modal from '@/components/entity/Modal'
import SwitchInput from '@/components/ui/SwitchInput'
import Input from '@/components/ui/Input'
import Checkbox from '@/components/ui/Checkbox'
import Button from '@/components/ui/Button'
import {ApiService} from '@/services/api.service'
import ErrorNotifierService from '@/services/errorNotifier.service'
import {TAPIEmployeeStatus} from '@/types/api.type'
import Select from '@/components/ui/Select'
import {TSelectOption} from '@/components/ui/Select/Select.vue'
import {validationMixin} from 'vuelidate'
import {maxValue, minValue, required, requiredIf} from 'vuelidate/lib/validators'
import Loader from '@/components/ui/Loader'
import Alert from '@/components/entity/Alert'

export type TModalLimitFormData = {
  status: TAPIEmployeeStatus
  limit: number | string
  tariffID: string[]
  periodID: string
  tripLimitCnt: number | string
  unlimited: boolean
}

export type TModalSubmitData = {
  data: TModalLimitFormData
  employeeIds: string[]
}

const notEmpty = (value: string[]) => !!value.length

@Component({
  mixins: [validationMixin],
  validations: {
    form: {
      tariffID: {notEmpty},
      periodID: {required},
      tripLimitCnt: {required, minValue: minValue(1), maxValue: maxValue(999)},
      limit: {
        requiredIf: requiredIf((form) => !form.unlimited),
        minValue: minValue(1),
        maxValue: maxValue(999999)
      }
    }
  },
  components: {
    Modal,
    SwitchInput,
    'InputText': Input.Text,
    Checkbox,
    Button,
    Select,
    Loader,
    Alert
  }
})
export default class ModalLimit extends Vue {
  @VModel({type: Boolean})
  state!: boolean

  @Prop({type: Array, default: () => []})
  readonly employeeIds: string[]

  @Prop(Number)
  readonly quantity: number

  @Prop(String)
  readonly title: string

  @Prop(Array)
  readonly tariffList: TSelectOption[]

  @Prop(Array)
  readonly periodList: TSelectOption[]

  @Prop([Object])
  readonly initials: TModalLimitFormData | null

  @Prop(Boolean)
  readonly limitWarn: boolean

  @Inject()
  readonly $api!: ApiService

  @Inject()
  readonly $error!: ErrorNotifierService

  form: TModalLimitFormData = {
    status: 'disabled',
    limit: '',
    tariffID: [],
    periodID: '',
    unlimited: true,
    tripLimitCnt: ''
  }

  loading: boolean = false

  @Watch('form.status')
  onStatusChanged (newValue: TAPIEmployeeStatus) {
    if (newValue === 'disabled') {
      this.resetForm()
    }
  }

  @Watch('initials')
  onInitialsChanged (newValue: TModalLimitFormData | null) {
    if (newValue) {
      const extraOptions = {
        ...(Array.isArray(newValue.tariffID) ? {} : {tariffID: []}),
        ...(Number(newValue.limit) === 0 ? {unlimited: true} : {}),
        ...(this.limitWarn ? {unlimited: false} : {})
      }
      this.form = {
        ...newValue,
        ...extraOptions
      }
    }
  }

  @Watch('warns')
  onWarnsChanged (newValue: keyof TModalLimitFormData) {
    this.$v.form[newValue]?.$touch()
  }

  get titleString () {
    if (this.title) {
      return this.title
    }

    const employeeText = getNoun(this.quantity, 'сотрудника', 'сотрудников', 'сотрудников')

    return `Управление лимитов для ${this.quantity} ${employeeText}`
  }

  get isFormDisabled () {
    const isServiceDisabled = this.form.status === 'disabled'
    const isQueued = this.form.status === 'processDisabled' || this.form.status === 'processEnabled'

    return isServiceDisabled || isQueued
  }

  resetForm () {
    this.form.status = 'disabled'
    this.form.limit = ''
    this.form.unlimited = true
    this.form.tariffID = []
    this.form.periodID = ''
    this.form.tripLimitCnt = ''
    this.$v.form.$reset()
  }

  async onSubmitClick () {
    this.loading = true

    if (this.form.status === 'enabled') {
      this.$v.$touch()

      if (this.$v.form.$invalid) {
        this.$v.form.$touch()
        this.loading = false

        return
      }
    } else {
      this.form.tariffID = this.tariffList.map(item => item.value)
      this.form.periodID = 'month'
      this.form.tripLimitCnt = '999'
    }

    try {
      const status: TAPIEmployeeStatus = this.form.status === 'disabled' ? 'processDisabled' : 'processEnabled'
      const data = this.employeeIds.map(item => ({
        ...this.form,
        status,
        limit: Number(this.form.limit),
        tripLimitCnt: Number(this.form.tripLimitCnt),
        employeeID: item
      }))
      await this.$api.patchEmployeeSettings(data)

      this.form.status = status
      this.emitSubmit()
      this.resetForm()
      this.state = false
    } catch (error) {
      this.$error.push({
        title: 'Ошибка сервера',
        message: 'Не удалось изменить статус сотрудников, для получения помощи свяжитесь с менеджером'
      })
    } finally {
      this.loading = false
    }
  }

  onCancelClick () {
    this.resetForm()
    this.state = false
  }

  onServiceSwitched () {
    this.form.status = this.form.status === 'disabled' ? 'enabled' : 'disabled'

    if (this.form.status === 'disabled') {
      this.form.limit = ''
      this.form.unlimited = true
      this.$v.form.$reset()
    }
  }

  onLimitInput (value: string) {
    if (value !== '0' && value !== '') {
      this.form.unlimited = false
    } else {
      this.form.unlimited = true
    }
  }

  onUnlimitedInput (value: boolean) {
    if (value) {
      this.form.limit = ''
    }
  }

  @Emit('submit')
  emitSubmit () {
    return {
      data: {...this.form},
      employeeIds: this.employeeIds
    } as TModalSubmitData
  }

  @Emit('update:limitWarn')
  emitUpdateLimitWarn () {
    return false
  }
}
