
import {Vue, Component, VModel, Prop} from 'vue-property-decorator'

@Component
export default class SwitchInput extends Vue {
  @VModel({type: Boolean}) state!: boolean

  @Prop(Boolean)
  readonly disabled?: boolean

  @Prop(Boolean)
  readonly error?: boolean

  get bodyClasses () {
    return {'c-switch-input__body_active': this.state}
  }

  get classes () {
    return {
      'c-switch-input_disabled': this.disabled,
      'c-switch-input_error': this.error
    }
  }

  onClick () {
    if (this.disabled) {
      return
    }

    this.state = !this.state
  }
}
